import 'bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';
import colorpicker from 'bootstrap-colorpicker';
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";

function updateNewDemoStatus_dc13d363() {
    $('#new-order-status-demo')
        .css('color', '#ffffff').css('background-color', $("#new-order-status-form input[name='color']").val() || '#7367F0')
        .text($('#new-order-status-form input[name="name"]').val());
}

function updateChangedDemoStatus_dc13d363() {
    $('#change-order-status-demo')
        .css('color', '#ffffff').css('background-color', $("#change-order-status-form input[name='color']").val() || '#7367F0')
        .text($('#change-order-status-form input[name="name"]').val());
}

function onSaveButtonNos_dc13d363(event) {
    let form = $("#new-order-status-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewOrderStatusForm_dc13d363(event) {
    toggleLoading('#saveButtonNOS', '#loadingButtonNOS');
    event.preventDefault();
    let form = $(this);
    let statusesHelper = $('#statuses_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = statusesHelper.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showOrderStatuses();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNOS', '#loadingButtonNOS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCOS_dc13d363(event) {
    let form = $("#change-order-status-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeOrderStatusForm_dc13d363(event) {
    toggleLoading('#saveButtonCOS', '#loadingButtonCOS');
    event.preventDefault();
    let form = $(this);
    let statusesHelper = $('#statuses_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = statusesHelper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showOrderStatuses();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCOS', '#loadingButtonCOS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initOrderStatusesPage() {
    let statusesHelper = $('#statuses_helper');

    window.colorpicker = colorpicker;

    window.showOrderStatuses = function () {
        $.ajax({
            url: statusesHelper.data('div'),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#order-statuses-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeStatus = function (id) {
        $.ajax({
            url: statusesHelper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-order-status").html(response.data);

                $("#change-order-status-form .colorpicker-group").colorpicker({
                    format: "rgb",
                    popover: false,
                    inline: true,
                    container: '.colorpicker-group'
                }).on('change', updateChangedDemoStatus_dc13d363);

                $('#change-order-status-form input[name="name"]').on('change', updateChangedDemoStatus_dc13d363);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteStatus = function (id, name) {
        deleteDialog(id, decodeURI(name), statusesHelper.data('delete-confirm'), statusesHelper.data('delete'), statusesHelper.data('delete-url'), () => {
            showOrderStatuses();
        });
    };

    showOrderStatuses();

    $("#new-order-status-form .colorpicker-group").colorpicker({
        format: "rgb",
        popover: false,
        inline: true,
        container: '.colorpicker-group'
    }).on('change', updateNewDemoStatus_dc13d363);

    $('#new-order-status-name').on('change', updateNewDemoStatus_dc13d363);
    $("#saveButtonNOS").on("click", onSaveButtonNos_dc13d363);
    $("#new-order-status-form").on("submit", onNewOrderStatusForm_dc13d363);
    $("#saveButtonCOS").on("click", onSaveButtonCOS_dc13d363);
    $("#change-order-status-form").on("submit", onChangeOrderStatusForm_dc13d363);
}

export function unloadOrderStatusesPage() {
    delete window.colorpicker;
    delete window.showOrderStatuses;
    delete window.changeStatus;
    delete window.deleteStatus;

    $('#new-order-status-name').off('change', updateNewDemoStatus_dc13d363);
    $("#saveButtonNOS").off("click", onSaveButtonNos_dc13d363);
    $("#new-order-status-form").off("submit", onNewOrderStatusForm_dc13d363);
    $("#saveButtonCOS").off("click", onSaveButtonCOS_dc13d363);
    $("#change-order-status-form").off("submit", onChangeOrderStatusForm_dc13d363);
}
