import 'ion-rangeslider/css/ion.rangeSlider.min.css';
import ionRangeSlider from 'ion-rangeslider';
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";

function onSaveButtonNST_4f33859d(event) {
    let form = $("#new-shipping-mode-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewShippingModeForm_4f33859d(event) {
    toggleLoading('#saveButtonNST', '#loadingButtonNST');
    event.preventDefault();
    let form = $(this);
    let shippingHelper = $('#shipping_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = shippingHelper.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showShippingModes();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNST', '#loadingButtonNST');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCST_4f33859d(event) {
    let form = $("#change-shipping-mode-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeShippingModeForm_4f33859d(event) {
    toggleLoading('#saveButtonCST', '#loadingButtonCST');
    event.preventDefault();
    let form = $(this);
    let shippingHelper = $('#shipping_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = shippingHelper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showShippingModes();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCST', '#loadingButtonCST');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initManageShippingModesPage() {
    let shippingHelper = $('#shipping_helper');

    window.ionRangeSlider = ionRangeSlider;

    window.showShippingModes = function () {
        $.ajax({
            url: shippingHelper.data('div'),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#shipping-types-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.getSliderValues = function (data) {
        $('#new-shipping-mode-form input[name="completionStart"]').val(data.from);
        $('#new-shipping-mode-form input[name="completionEnd"]').val(data.to);
    };

    window.getSliderValuesEdit = function (data) {
        $('#change-shipping-mode-form input[name="completionStart"]').val(data.from);
        $('#change-shipping-mode-form input[name="completionEnd"]').val(data.to);
    };

    window.changeShipping = function (id) {
        $.ajax({
            url: shippingHelper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-shipping-type").html(response.data);
                $("#change-shipping-type-completion-slider").ionRangeSlider({
                    skin: "flat",
                    type: "double",
                    grid: true,
                    min: 0,
                    max: 14,
                    postfix: shippingHelper.data('days'),
                    decorate_both: true,
                    drag_interval: true,
                    from: $('#change-shipping-mode-form input[name="completionStart"]').val(),
                    to: $('#change-shipping-mode-form input[name="completionEnd"]').val(),
                    onLoad: getSliderValuesEdit,
                    onChange: getSliderValuesEdit,
                    onFinish: getSliderValuesEdit
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteShipping = function (id, name) {
        deleteDialog(id, decodeURI(name), shippingHelper.data('delete-confirm'), shippingHelper.data('delete'), shippingHelper.data('delete-url'), () => {
            showShippingModes();
        });
    };

    showShippingModes();

    $("#new-shipping-type-completion-slider").ionRangeSlider({
        skin: "flat",
        type: "double",
        grid: true,
        min: 0,
        max: 14,
        postfix: shippingHelper.data('days'),
        decorate_both: true,
        drag_interval: true,
        from: 0,
        to: 14,
        onLoad: getSliderValues,
        onChange: getSliderValues,
        onFinish: getSliderValues
    });

    $("#saveButtonNST").on("click", onSaveButtonNST_4f33859d);
    $("#new-shipping-mode-form").on("submit", onNewShippingModeForm_4f33859d);
    $("#saveButtonCST").on("click", onSaveButtonCST_4f33859d);
    $("#change-shipping-mode-form").on("submit", onChangeShippingModeForm_4f33859d);
}

export function unloadManageShippingModesPage() {
    delete window.ionRangeSlider;
    delete window.showShippingModes;
    delete window.getSliderValues;
    delete window.getSliderValuesEdit;
    delete window.changeShipping;
    delete window.deleteShipping;

    $("#new-shipping-type-completion-slider").data('ionRangeSlider').destroy();

    $("#saveButtonNST").off("click", onSaveButtonNST_4f33859d);
    $("#new-shipping-mode-form").off("submit", onNewShippingModeForm_4f33859d);
    $("#saveButtonCST").off("click", onSaveButtonCST_4f33859d);
    $("#change-shipping-mode-form").off("submit", onChangeShippingModeForm_4f33859d);
}
